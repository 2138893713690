const appName = location.hostname.match(/(?:pr-\d+-)?(checkout|crew|tickets|video|myfestival)\.omr\.(?:com|ninja|io)/)?.[1]

const getAppCreator = async () => {
  switch (appName) {
    case 'checkout':
      return await import('./checkout/checkoutAppCreator')
    case 'crew':
      return await import('./crew/crewAppCreator')
    case 'tickets':
      return await import('./tickets/ticketsAppCreator')
    case 'video':
      return await import('./video/videoAppCreator')
    case 'myfestival':
      return await import('./myFestival/myFestivalAppCreator')
    default:
      return await import('./main/mainAppCreator')
  }
}

getAppCreator().then(({ create }) => create().mount('#app'))
